<template>
  <div class="behavior-container p-2 mt-1">
    <div class="d-flex justify-between behavior-content-header">
      <h4 class="text-primary">Comportamiento de pago</h4>
      <div class="d-flex gap-1">
        <img src="/images/decorations/round.png" alt="Anual" />
        <h3 class="text-secondary">Anual</h3>
      </div>
    </div>
    <div class="d-flex justify-between behavior-content-header"
    :class="scoringScore ? 'justify-between' : 'justify-content-end'"
    >
      <GaugeGraphic :scoringScore="scoringScore" v-if="scoringScore" />
      <div class="d-flex behavior-content-legends mt-2 justify-end">
        <div class="d-flex behavior-content-legends-content">
          <LegendItem color="green" label="Riesgo Bajo" />
          <LegendItem color="yellow" label="Riesgo Medio" />
          <LegendItem color="red" label="Riesgo Alto" />
        </div>
      </div>
    </div>
    <div class="behavior-content-carousel mt-3">
      <carousel :items-to-show="itemsShow">
        <slide v-for="(element, index) in scoringBehavior.data" :key="index">
          <div class="position-relative">
            <div
              class="
                round-container
                d-flex
                justify-center
                align-items-center
                pointer
              "
              :class="
                scoringBehavior.selected === index ? 'selected-behavior' : ''
              "
              @click="() => openBehaviorModal(index)"
            >
              <div class="round-payment" :class="getClass(element)"></div>
            </div>
            <h6
              v-if="index < scoringBehavior.data.length - 1"
              :style="index === 0 ? 'margin-left:1rem' : ''"
              class="p-absolute advance"
            ></h6>
            <h6 style="font-size: 11px" class="mt-0_5">
              {{
                getFormatDate(element.month + "-" + element.year, "behavior")
              }}
            </h6>
          </div>
        </slide>
        <template #addons>
          <navigation v-if="scoringBehavior?.data?.length > itemsShow" />
        </template>
      </carousel>
    </div>
    <div class="trafficLight-container">
      <div class="trafficLight-svg-container"></div>
      <div
        class="trafficLight-header justify-between"
        v-if="Object.values(charts).length > 0 && series.length > 0"
      >
        <div>
          <h6 class="traffic-header-font">
            Montos expresados en unidades de soles*
          </h6>
          <h5 class="text-primary">Semáforos de los ultimos 24 meses</h5>
        </div>
        <div></div>
      </div>

      <div
        class="
          behavior-container
          p-2
          mb-1
          mt-1
          d-flex
          chart-content
          justify-content-between
        "
        v-if="Object.values(charts).length > 0 && series.length > 0"
      >
        <div class="behavior-content-legends mt-2 d-flex">
          <div class="behavior-content-legends-content d-flex flex-column">
            <VueCustomTooltip
              label="Semáforo Verde:
Sin deudas vencidas."
              ><div class="legend-chart-v"></div
            ></VueCustomTooltip>
            <VueCustomTooltip
              label="Semáforo Gris:
No registra información de deudas."
              ><div class="legend-chart-g"></div
            ></VueCustomTooltip>
            <VueCustomTooltip
              label="Semáforo Amarillo:
Deudas con poco atraso."
              ><div class="legend-chart-a"></div
            ></VueCustomTooltip>
            <VueCustomTooltip
              label="Semáforo Rojo:
 Deudas con atraso significativo."
              ><div class="legend-chart-r"></div
            ></VueCustomTooltip>
          </div>
        </div>

        <div class="apexchart-wrapper flex-grow-1">
          <apexchart
            class="apexchartdiv"
            :options="charts"
            :series="series"
            type="line"
            height="350"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import LegendItem from "@/modules/user/components/scoring/LegendItem";
import GaugeGraphic from "@/components/GaugeGraphic";
import getFormatDate from "@/helpers/getFormatDate";
import useScoringBehavior from "@/modules/user/composables/scoring/useScoringBehavior";
import useGauge from "@/composables/useGauge";
import useScoring from "@/modules/user/composables/scoring/useScoring";
import { ref, onMounted } from "vue";

export default {
  components: {
    LegendItem,
    GaugeGraphic,
  },
  setup() {
    const { itemsShow, scoringBehavior, openBehaviorModal, getClass } =
      useScoringBehavior();
    const { scoringScore, charts, series } = useScoring();
    const { calcGauge } = useGauge();
    const meses = ref([]);
    onMounted(() => {
      setTimeout(() => {
        calcGauge();
      }, 150);
    });
    return {
      getFormatDate,
      itemsShow,
      scoringBehavior,
      openBehaviorModal,
      getClass,
      scoringScore,
      charts,
      series,
      meses,
    };
  },
};
</script>
