<template>
  <div class="d-flex align-items-center item-legend text-secondary-2">
    <div :class="`round round-${color}`"></div>
    <h6>{{ label }}</h6>
  </div>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: true
    }
  }
};
</script>
