import { useStore } from "vuex";
import { computed, ref, onBeforeMount, watch } from "vue";
import getFormatDate from "@/helpers/getFormatDate";

const useScoringBehavior = () => {
  const store = useStore();
  const itemsShow = ref(10);

  const scoringBehavior = computed(() => store.getters["user/scoringBehavior"]);

  const isModalAvailable = computed(
    () => Object.entries(scoringBehavior.value.current).length > 0
  );

  const getFormattedDate = (data) => {
    getFormatDate(`${data.month}-${data.year}`, "behavior");
  };
  const adjustModalPosition = () => {
    const modal = document.querySelector(".modal-info");

    if (window.innerWidth > 992) {
      itemsShow.value = 10;
      modal.style.left = "58%"
      modal.style.transform = 'translateX(-50%)';
      modal.style.position = "absolute";
      modal.style.top = "450px";

    } else {
      itemsShow.value = 4;
      modal.style.left = "50%";
      modal.style.position = "fixed";
      modal.style.top = "50%";
    }
  };
  const openBehaviorModal = (index) => {
    const behavior = scoringBehavior.value.data[index];

    store.commit("user/scoringBehavior", {
      isOpen: true,
      selected: index,
      current: behavior,
    });
    adjustModalPosition();
  };
  const closeBehaviorModal = () => {
    store.commit("user/scoringBehavior", {
      isOpen: false,
      selected: null,
    });
    setTimeout(() => {
      store.commit("user/scoringBehavior", {
        current: {},
      });
    }, 200)
  };

  const getClass = (info) => {
    const sortedInfo = [
      { ...info.high, status: "round-payment-red-high" },
      { ...info.medium, status: "round-payment-yellow-medium" },
      { ...info.low, status: "round-payment-green-low" },
    ].sort((a, b) => parseInt(b.percentage) - parseInt(a.percentage));

    return sortedInfo[0].status;
  };

  onBeforeMount(() => {
    itemsShow.value = window.innerWidth > 992 ? 10 : 4;
  });



  return {
    itemsShow,
    scoringBehavior,
    openBehaviorModal,
    getClass,
    closeBehaviorModal,
    isModalAvailable,
    getFormattedDate,
  };
};
export default useScoringBehavior;
