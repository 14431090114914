<template>
  <div class="p-relative modal-behavior">
    <transition name="fade">
      <div
        class="modal-info"
        :class="scoringBehavior.isOpen ? 'open' : 'close'"
      >
        <template v-if="isModalAvailable">
          <CloseButton @close="closeBehaviorModal" />
          <div class="modal-info-content-container">
            <h4 class="mb-1">
              {{ getFormattedDate(scoringBehavior.current) }}
            </h4>
            <div class="modal-info-content">
              <BehaviorItem
                title="Riesgo Bajo"
                :data="scoringBehavior.current.low"
                color="green"
              />
              <div class="vertical-line"></div>
              <BehaviorItem
                title="Riesgo Medio"
                :data="scoringBehavior.current.medium"
                color="yellow"
              />
              <div class="vertical-line"></div>
              <BehaviorItem
                title="Riesgo Alto"
                :data="scoringBehavior.current.high"
                color="red"
              />
            </div>
          </div>
        </template>
      </div>
    </transition>
  </div>
</template>

<script>
import useScoringBehavior from "@/modules/user/composables/scoring/useScoringBehavior";
import CloseButton from "@/modules/user/components/scoring/ModalBehaviorClose";
import BehaviorItem from "@/modules/user/components/scoring/ModalBehaviorItem";

export default {
  components: {
    CloseButton,
    BehaviorItem,
  },
  setup() {
    const {
      closeBehaviorModal,
      scoringBehavior,
      isModalAvailable,
      getFormattedDate,
    } = useScoringBehavior();

    return {
      closeBehaviorModal,
      scoringBehavior,
      getFormattedDate,
      isModalAvailable,
    };
  },
};
</script>
