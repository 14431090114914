<template>
  <div class="item-behavior-info">
    <div
      class="d-flex gap-1 mb-1 align-items-center item-legend text-secondary-2"
    >
      <div :class="`round round-${color}`"></div>
      <h6 :class="`text-${color}`">{{ title }}</h6>
    </div>
    <div class="d-flex flex-column">
      <p class="mb-0_5">N° de documentos: {{ data.numberDocuments }}</p>
      <p class="mb-0_5">Monto: {{ formatAmount(data.amount) }}</p>
      <p class="mb-0_5">Porcentaje: {{ formatPercentage(data.percentage) }}</p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
    color: {
      type: String,
      required: true,
    },
  },
  methods: {
    formatAmount(amount) {
      return `${amount} PEN`;
    },
    formatPercentage(percentage) {
      return `${percentage.toFixed(2)} %`;
    },
  },
};
</script>
