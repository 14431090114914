<template>
  <div
    class="table-debt-details mb-1 mt-2"
    v-if="Object.values(charts).length > 0 && series.length > 0"
  >
    <div class="header-table">
      <h2 class="text-primary">Detalle de la deuda SBS/Microfinanzas*</h2>
    </div>

    <div
      class="grid-header"
      :style="{
        gridTemplateColumns: 'repeat(10, 1fr)',
        gridTemplateRows: 'auto auto',
      }"
    >
      <div class="grid-item grid-item-row">Entidad</div>
      <div class="grid-item" style="grid-column: span 3">Deuda Anterior</div>
      <div class="grid-item" style="grid-column: span 4">
        Deuda a {{ lastMonth }}
      </div>
      <div class="grid-item grid-item-row" :style="{ gridColumn: '9' }">
        Rect.
      </div>
      <div class="grid-item grid-item-row" :style="{ gridColumn: '10' }">
        Disc.
      </div>

      <div class="grid-item" v-for="month in months" :key="month">
        {{ month }}
      </div>

      <div class="grid-item">Fecha Inf.</div>
      <div class="grid-item">Calif. SBS/ Microf.</div>
      <div class="grid-item">Monto *</div>
      <div class="grid-item">Días Venc.</div>
    </div>

    <template v-if="entities.length > 0">
      <div
        class="grid-body"
        v-for="entity in entities"
        :style="{
          gridTemplateColumns: 'repeat(10, 1fr)',
        }"
      >
        <div class="grid-item">{{ entity.Name }}</div>

        <div
          v-for="(month, index) in months"
          :key="index"
          class="grid-item"
          style="background-color: #21ae81"
        >
          {{
            entity.dataByMonth[month]
              ? new Intl.NumberFormat("es-PE", {
                  style: "currency",
                  currency: "PEN",
                }).format(entity.dataByMonth[month])
              : "S/ 0.00"
          }}
        </div>

        <div class="grid-item">
          {{ entity.ReportDate ? getFormatDate(entity.ReportDate) : "-" }}
        </div>
        <div class="grid-item" style="background-color: #21ae81">
          {{ entity.Rating }}
        </div>
        <div class="grid-item">
          {{
            new Intl.NumberFormat("es-PE", {
              style: "currency",
              currency: "PEN",
            }).format(entity.DebtBalance)
          }}
        </div>
        <div class="grid-item">{{ entity.DaysDue }}</div>
        <div class="grid-item">-</div>
        <div class="grid-item">-</div>
      </div>
      <div
        class="grid-total"
        :style="{
          gridTemplateColumns: 'repeat(10, 1fr)',
        }"
      >
        <div class="grid-ite-total">Total</div>
        <div class="grid-ite-total">
          {{
            new Intl.NumberFormat("es-PE", {
              style: "currency",
              currency: "PEN",
            }).format(totalDebtByMonth[3])
          }}
        </div>
        <div class="grid-ite-total">
          {{
            new Intl.NumberFormat("es-PE", {
              style: "currency",
              currency: "PEN",
            }).format(totalDebtByMonth[2])
          }}
        </div>
        <div class="grid-ite-total">
          {{
            new Intl.NumberFormat("es-PE", {
              style: "currency",
              currency: "PEN",
            }).format(totalDebtByMonth[1])
          }}
        </div>
        <div class="grid-ite-total">-</div>
        <div class="grid-ite-total">-</div>
        <div class="grid-ite-total">
          {{
            new Intl.NumberFormat("es-PE", {
              style: "currency",
              currency: "PEN",
            }).format(totalDebtByMonth[0])
          }}
        </div>
        <div class="grid-ite-total">-</div>
        <div class="grid-ite-total">-</div>
        <div class="grid-ite-total">-</div>
      </div>
    </template>

    <div class="grid-body-empty" v-else>
      <p>Sin datos</p>
    </div>
  </div>
</template>

<script>
import getFormatDate from "@/helpers/getFormatDate";
import useScoringDebtDetails from "@/modules/user/composables/scoring/useScoringDebtDetails";
import { ref, watch } from "vue";
import useScoring from "@/modules/user/composables/scoring/useScoring";

export default {
  setup() {
    const {
      debtDetails,
      availableMonths,
      entities,
      getMonthName,
      getDebtByMonth,
      totalDebtByMonth,
    } = useScoringDebtDetails();

    const { charts, series } = useScoring();

    const months = ref([...availableMonths.value]);
    const lastMonth = ref(months.value.pop());
    watch(availableMonths, (newVal) => {
      months.value = [...newVal];
      lastMonth.value = months.value.pop();
    });

    return {
      debtDetails,
      availableMonths,
      entities,
      getMonthName,
      getDebtByMonth,
      months,
      lastMonth,
      totalDebtByMonth,
      getFormatDate,
      charts,
      series,
    };
  },
};
</script>
<style></style>
