<template>
  <DashboardLayout>
    <main class="file-container">
      <section class="file-content">
        <article class="form-content">
          <Header title="Scoring" />

          <LoadingComponent v-if="getSearch.isLoading" />
          <div
            id="capture"
            ref="capture"
            v-else-if="
              !getSearch.isLoading &&
              Object.values(getSearch.response).length > 0
            "
          >
            <Behavior />
            <TableDebtDetails />
            <DataOverview />
            <TableScoring
              title="Utilización de Líneas de Crédito"
              :headers="[
                'Instituciones',
                '**',
                'Línea Aprobada',
                'Línea No Utilizada',
                'Línea Utilizada',
                '% L. Uti.',
              ]"
              :bodies="[...creditLineUsage]"
              :columns="6"
            >
              <template #footer>
                <p class="footer-table">
                  **(DES): Descuentos, (PRE): Préstamos, (AVP): Avales y Fianzas
                </p>
              </template>
            </TableScoring>

            <TableScoring
              title="Documentos Protestados sin Regularizar"
              :headers="['# Doc.', 'Monto (S/) *', 'Max Días Venc.']"
              :bodies="[...documentsNotRegularized]"
              firstColumnColor
              textFirstColumn="Total"
              :columns="4"
              isTableBorder
              :hasMarginBottom="false"
            />

            <TableScoring
              title="Documentos Protestados Regularizados"
              :headers="['# Doc.', 'Monto (S/) *', 'Max Días Venc.']"
              :bodies="[...documentsRegularized]"
              firstColumnColor
              textFirstColumn="Total"
              :columns="4"
              isTableBorder
              :hasMarginBottom="false"
            />

            <div class="flex-btn-download">
              <button
                class="btn btn-secondary btn-download d-flex gap-0-2"
                @click="takeScreenshot"
              >
                <i class="fas fa-download"></i>
                <span class="text-white"> Descargar </span>
              </button>
            </div>
          </div>
        </article>
      </section>
    </main>
  </DashboardLayout>
  <ModalBehavior />
</template>
<script>
import DashboardLayout from "@/modules/shared/layouts/DashboardLayout";
import Header from "@/modules/shared/layouts/Header";
import FormSearch from "@/modules/user/components/scoring/FormSearch";
import LoadingComponent from "@/components/LoadingComponent";
import TableScoring from "@/modules/shared/components/Table";
import HeaderTable from "@/modules/shared/components/HeaderTableCreditBureaus";
import FooterTable from "@/modules/shared/components/FooterTableCreditBureaus";

import DataOverview from "@/modules/user/components/scoring/DataOverview";
import Behavior from "@/modules/user/components/scoring/Behavior";
import useScoringSentinel from "@/modules/user/composables/scoring/useScoringSentinel";
import useScoring from "@/modules/user/composables/scoring/useScoring";
import ModalBehavior from "@/modules/user/components/scoring/ModalBehavior";
import TableDebtDetails from "@/modules/user/components/scoring/TableDebtDetails";
import html2canvas from "html2canvas";
import { onMounted } from "vue";

export default {
  components: {
    DashboardLayout,
    Header,
    FormSearch,
    TableScoring,
    LoadingComponent,
    DataOverview,
    HeaderTable,
    FooterTable,
    Behavior,
    ModalBehavior,
    TableDebtDetails,
  },
  methods: {
    takeScreenshot() {
      const element = this.$refs.capture;
      html2canvas(element).then((canvas) => {
        const image = canvas.toDataURL("image/png");

        const link = document.createElement("a");
        link.href = image;
        link.download = "scoring.png";
        link.click();
      });
    },
  },

  setup() {
    const {
      getSearch,
      creditLineUsage,
      documentsRegularized,
      documentsNotRegularized,
    } = useScoringSentinel();

    const { search } = useScoring();

    onMounted(() => {
      search();
    });

    return {
      getSearch,
      creditLineUsage,
      documentsRegularized,
      documentsNotRegularized,
    };
  },
};
</script>
