import { computed } from "vue";
import { useStore } from "vuex";
import months from "@/constants/months";

const useScoringDebtDetails = () => {
  const store = useStore();

  const getMonthName = (month) => {
    return months[month - 1];
  };
  const debtDetails = computed(() => store.getters["user/scoringDebtDetail"]);

  const availableMonths = computed(() => {
    return debtDetails.value.map(
      ({ resultado }) => getMonthName(resultado.Mes) + " " + resultado.Ano
    ).reverse();
  });
  let totalDebtByMonth = {};
  const entities = computed(() => {

    let entitiesMap = {};
    let index = 0;
    if (debtDetails.value && Array.isArray(debtDetails.value)) {

      debtDetails.value.forEach(({ resultado }) => {
        if (resultado && Array.isArray(resultado.Entidad)) {
          resultado.Entidad.forEach((entity) => {
            if (!entitiesMap[entity.NomEnt]) {
              entitiesMap[entity.NomEnt] = {
                Name: entity.NomEnt,
                dataByMonth: {},
                Rating: entity.Cal,
                ReportDate: entity.FchPro,
                DebtBalance: entity.SalDeu,
                DaysDue: entity.DiaVen,
              };
            }

            const monthYearKey =
              getMonthName(resultado.Mes) + " " + resultado.Ano;
            totalDebtByMonth[index] = resultado.TotalSalDeu;
            entitiesMap[entity.NomEnt].dataByMonth[monthYearKey] =
              entity.SalDeu;
          });
          index++
        }
      });
    }
    return Object.values(entitiesMap);

  });

  const getDebtByMonth = (entity, month) => {
    return entity.dataByMonth[month] || "-";
  };

  return {
    debtDetails,
    availableMonths,
    entities,
    getMonthName,
    getDebtByMonth,
    totalDebtByMonth
  };
};

export default useScoringDebtDetails;
